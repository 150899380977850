html,
body,
#root {
  height: 100%;
}

@media only screen and (max-width: 768px) {
  #root {
    height: auto;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
}

body {
  background-color: #fff;
}

img {
  max-width: 100%;
}

body {
  font-family: Arial, sans-serif;
}

.ant-form-explain,
.ant-form-explain-holder {
  display: none;
}

.ant-form-item {
  margin-bottom: 14px;
}
